.inquire-form-input{
    border-radius: .5rem;
    width: 100%;
    height: 3rem;
    margin-bottom: .8rem; 
    border: none;
} 
.cardhoverstyle{
    flex-direction: column;
}
.inquire-form-msg{
    border-radius: .5rem;
    width: 100%;
    height: 4rem;
    margin-bottom: 0px; 
    border: none;
}

.bookconsultation-fixed{
   position: sticky;
   top: calc(12vh + 2rem);
}
.bookconsultation-notfixed{
    position: static;
}

.shortstaycard:hover{
    background-color: #F5F5F5;
    cursor: pointer;
}
.MuiDialogContent-root::-webkit-scrollbar{
    display: none;
}
.MuiDialogContent-root {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  /* .inquire-form-input::placeholder{
      position: relative;
      left: 10px;
  } */

  /* .inquire-form-input::-webkit-input-placeholder { 
    position: relative;
      left: 10px;
  } */
  
  /* .inquire-form-input:-ms-input-placeholder {
    position: relative;
      left: 10px;
  } */

  .cardhoverstyle:hover{
    background-color: #F5F5F5 !important;
    cursor: pointer;
}

.faq-column{
    columns: 2 250px;
    column-gap:2rem;
    column-rule: 1px dotted #7eeeee;
}
@media screen and (max-width: 600px){
    .faq-column{
        column-gap: 1rem;
    }
}
