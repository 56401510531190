@media  (min-width: 1200px) {
    .visas-container{
        padding: 1rem 4rem 3rem !important;
    }
    .additional-services{
        padding: 2rem 0rem 3rem !important;
    }
    .services-box{
        width:calc(100vw - 12rem) !important;
    }
}