body {
  --tblue: #2F2FA2;
  --tblack: #111111;
  --tgery: #898989;
  --bgbluepink: #ADADE5;
  --bgsection1: #FEF0F3;
}

.visa-section5-box {
  background-image: url(./Images/Box.svg);
  min-width: 2rem;
  /* min-height: 2rem; */
  /* vertical-align: center; */
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

.page {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;

}

/* Simple enter transition 300ms exit 150ms */
.fade-appear,
.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in-out 20ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 80ms ease-in-out;
}

input[type=text],
input[type=email] {
  padding-left: 16px !important;
}

textarea {
  font-family: Source Sans Pro, sans-serif !important;
  color: black !important;
  padding: 1rem 5px;
  font-weight: 500;
  padding-left: 16px !important;
}

#google_translate_element {
  width: fit-content;
  position: absolute;

}

.goog-te-gadget-simple {
  background: transparent;
  border: none;
  background-color: #F0F0FF;
  border-left: none;
  border-top: none;
  border-bottom: none;
  border-right: none;
  font-size: 10pt;
  display: inline-block;
  padding-top: 1px;
  padding-bottom: 2px;
  cursor: pointer;
  zoom: 1;
}

.goog-te-gadget-simple img {
  display: none;
}

.goog-te-gadget-simple .goog-te-menu-value {
  color: #2F2FA2;
}

.goog-te-banner-frame.skiptranslate {
  display: none !important;
}

body {
  top: 0px !important;
}

.icon-box-home-about {
  margin-top: 4px;
}

.icon-box-home-about>img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.scroll-btn-div {
  position: fixed;
  bottom: 30px;
  left: 30px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: white;
  opacity: .8;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  box-shadow: 4px 4px 20px #00000029;
  transition: all 1s ease-in;
}

.react-tel-input {
  font-family: Source Sans Pro, sans-serif !important;
  font-size: 16px !important;
  margin-bottom: 14px !important;
}

.react-tel-input .form-control {
  position: relative;
  font-size: 16px !important;
  letter-spacing: .01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 52px !important;
  margin-left: 0;
  background: #FFFFFF;
  border: none !important;
  border-radius: 0.5rem !important;
  line-height: 25px;
  height: 3rem !important;
  width: 100% !important;
  outline: none;
}

.react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  background-color: #f5f5f5;
  border-radius: 8px 0 0 8px !important;
}

.react-tel-input .selected-flag {
  width: 42px !important;
}

.react-tel-input .country-list {
  max-height: 100px !important;
}

.blog_text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  padding-bottom: 0px !important;
  margin-bottom: 8px;
}

.blog_card_content {
  height: calc(100% - 150px);
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.blog-img {
  width: 100%;
  height: auto;
  max-height: 600px;
  object-fit: cover;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}