.currency_slider{
    transition: all .3s ease-out;
    background-color: #2F2FA2;
    width: 70px;
    height: 40px; 
    position: absolute; 
    z-index:  -1;
    border-radius: 12px;
}
.spouse_slider{
    transition: all .3s ease-out;
    background-color: #F0F0FF;
    width:  70px;
    height: 40px; 
    position: absolute; 
    z-index:  -1;
    border-radius: 10px;
}
.cost-side-box{
    padding: 2rem;
}

@media screen and (max-width: '600px') {
    .cost-side-box{
        padding: 1rem;
    }
}