
.vdt-options:hover{
    border: 1px solid #dedede !important;
}

.collapse-options:hover{
    border: 1px solid #dedede !important;
}
@media (min-width: 1200px) {
    .vdt-side-box{
        padding: 3rem 4rem !important;
    }
    .vdt-img{
        padding: 0 5rem 1rem !important;
    }
    .vdt-options :hover p{
        color: white;
    }
    .vdt-options :hover{
        background-color: #2F2FA2;
    }
}