
.req-consultation-sticky-div{
    background: white 0% 0% no-repeat padding-box;
    box-shadow: 4px 4px 20px #00000029;
    border-radius: .75rem;
    opacity: .8 ;
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    padding: 1rem;
    display: inline-flex;
    justify-content: space-between;
    /* position: absolute; */
    position: sticky;
    /* position: fixed; */
    z-index: 9900;
}

.greenv-invest-options::before{
    content: '→';
    margin-left: .5rem;
    position: relative;
    top: -1px;
    padding-right: 1rem;
}