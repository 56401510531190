header a{
    text-decoration: none;
    font-size: 1rem;
    color: #2F2FA2;
}
.header{
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    box-shadow: 4px 4px 8px #00000029 !important;
    opacity: 1;
    backdrop-filter: blur(40px) !important;
    -webkit-backdrop-filter: blur(40px) !important;
    margin: 0rem auto 0 !important;
    width: calc(100vw - 2rem) !important;
    height: 12vh;
}
.notfixed{
    position: static !important;
    margin-top: 1rem !important;
}
.fixed{
    position: fixed !important;
    z-index: 9900;
    left: 0;
    right: 0;
}
.header-mobile-op-container{
    top: calc(12vh + 2rem) !important;
    left: 0rem !important;
    right: 0rem !important;
    text-align: center !important;
    opacity: .6 !important;
    border-radius: 1.5vw;
    backdrop-filter: blur(40px) !important;
-webkit-backdrop-filter: blur(40px) !important;
box-shadow: 4px 4px 8px #00000029 !important;
margin: 0 auto;
}

 
.logo-container{
    background:transparent url('../../Images/Visa\ Discovery\ Logo\ desktop.png') 0% 0% no-repeat padding-box;
    background-size: cover;
    opacity: 1;
}
.logo-container:hover{
    cursor: pointer;
}

.header-options{
    width: 80%;
    justify-content: space-around;

}

.header-options>a p{
    font: normal normal normal 1rem/25px Source Sans Pro;
letter-spacing: 0px;
color: #2F2FA2;
opacity: 1;
}

.header-options>p:hover{
    cursor: pointer;
}
.home-section1{
    width: calc(100vw - 0rem);
    margin: auto;
    /* height: 100vh; */
    background-color: var(--bgsection1);
    background-repeat: no-repeat;
    background-size:cover;
    padding: 1rem 0rem;
    max-width: 100%;
}
.home-section3{
    background-color:#F0F0FF;
}
.main-text{
    vertical-align: center;
    position: absolute;
}

.discover-now-box{
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    /* background: #330000 0% 0% no-repeat padding-box; */
    /* opacity: 1; */
    border: none;
    background-color: #EAD5F6;
}

.passport-image-container{
    background-image: url("../../Images/passport\ img.png");
    background-size: cover;
    background-repeat: no-repeat;
}

.home-section3-logo{
    background: transparent url('../../Images/logo.png') 0% 0% no-repeat padding-box;
    mix-blend-mode: luminosity;
    opacity: 1;
    background-size: contain;
    width: 200px;
    height: 130px;
}
.home-section4-img-box{
    background-image: url('../../Images/Rectangle\ 57.png');
    opacity: 1;
    background-size: auto;
    background-repeat: no-repeat;
}

.home-section6-container-wrapper{
    background: transparent linear-gradient(254deg, #FFFFFF 0%, #CECEFF 100%) 0% 0% no-repeat padding-box;
    position:relative;
    z-index: 8800;
    margin-top: -6.5rem;
    margin-bottom: -6.5rem;
}

.home-section6-container{
    background: transparent linear-gradient(107deg, #E165FF00 0%, #ADADE5 100%) 0% 0% no-repeat padding-box;
    box-shadow: 4px 4px 20px #00000029;
    opacity: 1;
}
footer a{
    text-decoration: none;
}
.footer-container{
    background-color:black;
    width: 100vw;
    max-width: 100%;
    padding-top:10rem;
    padding-bottom: 5rem;
    padding-right:6.5rem;
    padding-left: 6.5rem;
}