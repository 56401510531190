
/* .MuiTooltip-tooltip{
    background-color: white;
    color: #898989;
    box-shadow: 4px 4px 20px #00000029;
    border-radius: 10px;
} */
.MuiTooltip-popper>div{
    /* display: none !important; */
    background-color: white;
    color: #898989;
    box-shadow: 4px 4px 20px #00000029;
    border-radius: 10px;
    padding: 10px 15px !important;
    font: normal normal normal 14px/20px Source Sans Pro !important;
}