.about-us-cover-container{
    background-image: url('../../Images/nick-karvounis-Prb-sjOUBFs-unsplash.svg');
    background-size: cover;
    /* background-repeat: no-repeat; */
    background-color: #2F2FA2;
    /* background-blend-mode: multiply; */
    background-blend-mode: hard-light;
}
.team-name-text{
    font-size: 1.2rem !important;
    color: rgb(47, 47, 162) !important;
}
.team-member-img{
    height: 30rem;
    width: 100%;
    object-fit: cover;
    display: block;
    border-radius: 16px;
}
@media screen and (min-width: 900px){
    .about-us-divider-images{
        width: 33.33% !important;
    }
}