.header-options-mobile{
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    box-shadow: 4px 4px 8px #00000029 !important;
    border-radius: 0 0 1.5vw 1.5vw;
    opacity: 1;
    backdrop-filter: blur(40px) !important;
    -webkit-backdrop-filter: blur(40px) !important;
    /* position: fixed; */
    z-index: 9900;
    padding: 1em 0;
    display: none;
    /* animation: mobileoptions .3s ease-out forwards; */
}
/* .header-options-mobile .full {
    max-height: 1000px !important;
    transition: max-height 1s ease-in-out;
} */
#nav-icon4 {
    width: 35px;
    height: 45px;
    position: relative; 
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
  }
  #nav-icon4.open{
      top: 3px;
  }
  #nav-icon4 span {
    display: block;
    position: absolute;
    height: 5px;
    width: 100%;
    background: #2f2fa2;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }
  
  /* Icon */
  
  #nav-icon4 span:nth-child(1) {
    top: 15px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }
  
  
  #nav-icon4 span:nth-child(2) {
    top: 30px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }
  
  #nav-icon4.open span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 5px;
    left: 5px;
  }
  
  
  #nav-icon4.open span:nth-child(2) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 30px;
    left: 5px;
  }
/* @keyframes mobileoptions {
    0%{
        height: 0;
    }
    100%{
        height: fit-content;
    }
} */

.header-options-mobile a{
    text-decoration: none;
    font-size: 1rem;
    color: #2F2FA2;
}

.visas-options-mobile,
.header-options-mobile p{
    font: normal normal normal 1rem/25px Source Sans Pro;
    letter-spacing: 0px;
    color: #2F2FA2;
    opacity: 1;
    cursor: pointer;
}

@media screen and (max-width: 900px) {
    .header-options-mobile{
        display: block !important;
    }
    .logo-container{
        opacity: 1 !important;
    }
    .footer-container{
        padding-bottom: 5rem;
        padding-right: 2rem;
        padding-left: 2rem;
    }
    /* .header{
        border-radius: 1.5vw 1.5vw 0 0;
    } */
    .home-section1{
        width: 100vw;
        height: auto;
    }
    .home-section3-logo{
        width: 130px;
        height: 95px;
    }
    .home-section4-img-box{
        background-image: url('../../Images/Rectangle\ 57.png');
        opacity: 1;
        background-size: cover;
        background-repeat: no-repeat;
    }
}
@media screen and (max-width:350px){
    .home-section4-container{
        height: 150vh !important;
    }
}