.services-cover-container, .contact-us-cover-container{
    background-image: url('../../Images/clifford-5ZB0w0vSgPk-unsplash.svg');
    background-size: cover;
    /* background-repeat: no-repeat; */
    background-color: #2F2FA2;
    /* background-blend-mode: multiply; */
    background-blend-mode: hard-light;
    /* opacity: .3; */
}
.contactoptions::after{
    content: '→';
    margin-left: .5rem;
    position: relative;
    top: -1px;
}
.phone::before{
    background-image: url(../../Images/Icon\ feather-phone-call.svg);
}
.email::before{
    background-image: url(../../Images/Icon\ feather-mail-2.svg);
}
.calender::before{
    background-image: url(../../Images/Icon\ feather-calendar.svg);
}
.whatsapp::before{
    background-image: url(../../Images/Icon\ simple-whatsapp.svg);
}
.phone::before, .email::before, .calender::before, .whatsapp::before{
    background-size:contain;
    display: inline-block;
    width: 25px; 
    height: 25px;
    content: '';
    position: relative;
    top: 6.5px;
    margin-right: 1.5rem;
    background-repeat: no-repeat;
}